
// bread crumb style here

.golfy-breadcrumb-area{
    background-image: url(../images/banner/breadcrumb-1.jpg);
    height: 480px;
    position: relative;
    @media #{$small-mobile} {
        height: +350px;
    }
    .breadcrumb-inner{
        position: absolute;
        left: 50%;
        top: 50%;
        min-width: 100%;
        transform: translate(-50%, -50%);
        .title{
            color: #FFF;
            text-align: center;
            font-size: 64px;
            font-style: normal;
            font-weight: 400;
            line-height: 74px;
            text-transform: capitalize;
            margin-bottom: 0;
            @media #{$large-mobile} {
                font-size: 48px;
            }
            @media #{$small-mobile} {
                font-size: 36px;
                margin-bottom: 0;
                line-height: 36px;
            }
        }
    }
}

.golfy-breadcrumb-area.wedding{
    background-image: url(../images/banner/breadcrumb-2.jpg);
}

.counter-up-area{
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media #{$md-layout} {
        flex-wrap: wrap;
        justify-content: center;
        gap: 80px;
    }
    @media #{$sm-layout} {
        flex-wrap: wrap;
        justify-content: center;
        gap: 80px;
    }
}


.rts-brand-area .section-inner .brand-image img {
    filter: grayscale(1);
    transition: .3s;
    cursor: pointer;
}


.rts-brand-area .section-inner .brand-image img{
    &:hover{
        filter: grayscale(0);
    }
}







