// **************************************** =========== Start Blog Scss  ===========  ****************************************//

.rts-blog-area{
    @media(max-width:1366px){
        padding-left: 10px;
        padding-right: 10px;
    }
    .rts-section-title{
        max-width: 36%;
        margin: auto;
        .heading-title{
            margin-bottom: 60px;
            font-weight: 400;
        }
        @media(max-width:1200px){
            max-width: 55%;
        }
        @media #{$sm-layout} {
            max-width: 100%;
            margin: 0 15px 60px;
        }
        @media #{$small-mobile} {
            margin: 0;
        }
    }
    .section-inner{
        .blog-wrapper{
            .image-area{
                margin-bottom: 30px;
                overflow: hidden;
                transition: all .3s;
                &:hover{
                    img{
                        transform: scale(110%);
                    }
                }
                img{
                    transform: scale(100%);
                    transition: all .3s;
                    @media(max-width:768px){
                        width: 100%;
                    }
                }
            }
            .content{
                .sub-title{
                    font-size: 15px;
                    font-weight: 400;
                    line-height: 25px;
                    text-transform: capitalize;
                    margin-bottom: 20px;
                }
                .event-title{
                    a{
                        color: var(--color-title);
                        font-size: 24px;
                        line-height: 1.2;
                        font-family: var(--title-font);
                        text-transform: capitalize;
                        @media(max-width:1200px) and (min-width:991px) {
                            font-size: 18px;
                        }
                        @media(max-width:991px) and (min-width:768px){
                            font-size: 20px;
                        }
                        @media(max-width:450px){
                            font-size: 18px;
                        }
                    }
                }
                .read-more-btn{
                    position: relative;
                    padding-left: 30px;
                    color: var(--color-title);
                    transition: all .3s;
                    display: block;
                    &:hover{
                        color: var(--color-primary);
                        &::before{
                            width: 0;
                            left: 22px;
                        }
                        &::after{
                            width: 20px;
                        }
                    }
                    &::before{
                        content: '';
                        position: absolute;
                        width: 20px;
                        height: 1px;
                        background: var(--color-title);
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        transition: all .3s;
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        width: 0;
                        height: 1px;
                        background: var(--color-primary);
                        left: 0;
                        top: 50%;
                        transform: translateY(-50%);
                        color: var(--color-title);
                        transition: all .3s;
                    }
                }
            }
        }
    }
    &.home-2{
        .section-inner{
            .blog-wrapper{
                .image-area{
                    margin-bottom: 0;
                    img{
                        @media(max-width:991px){
                            width: 100%;
                        }
                    }
                }
                .content{
                    background: #F6F6F6;
                    padding: 30px 30px 50px 30px;
                    @media(max-width:450px){
                        padding: 30px 15px 30px 15px;
                    }
                    .content-bottom{
                        display: flex;
                        justify-content: space-between;
                        .date-time{
                            .date{
                                font-size: 20px;
                                color: var(--color-title);
                                margin-bottom: 10px;
                            }
                        }
                        .rts-btn{
                            width: max-content;
                            height: max-content;
                            padding: 10px 23px;
                            @media(max-width:450px){
                                padding: 8px 20px;  
                            }
                        }
                    }
                }
            }
        }
    }
    &.home-3{
        .section-inner{
            .blog-wrapper{
                .image-area{
                    margin-bottom: 0;
                }
                .content{
                    background: var(--color-white);
                    padding: 40px 20px 40px 40px;
                    @media #{$large-mobile} {
                        padding: 20px;
                    }
                    @media #{$small-mobile} {
                        padding: 15px;
                    }
                }
            }
        }
    }
}