
.wedding-top-swiper-area{
    position: relative;
    .swiper-button-next,
    .swiper-button-prev{
        color: #fff;
        font-size: 24px;
        &::after{
            font-size: 24px;
        }
    }
    .parallax-bg{
        height: 600px;
    }
}



.promissing-couple{
    text-align: center;
    padding: 0 150px;
    @media #{$md-layout} {
        padding: 0 30px;
    }
    @media #{$sm-layout} {
        padding: 0 0;
    }
    .title{
        color: #111;
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        text-transform: capitalize;
    }
    p.disc{
        color: #6F7073;
        text-align: center;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
    }
    .nav-couple{
        ul{
            padding-left: 0;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 121px;
            list-style: none;
            @media #{$md-layout} {
                gap: 40px;
            }
            @media #{$sm-layout} {
                gap:  20px;
            }
            li{
                a{
                    color: #111;
                    text-align: center;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 50px;
                    text-transform: capitalize;
                    transition: .3s;
                    @media #{$large-mobile} {
                        font-size: 16px;
                    }
                    &:hover{
                        color: var(--color-primary);
                    }
                }
            }
        }
        position: relative;
        .leaf-area{
            .img-left-b{
                position: absolute;
                left: 50px;
                bottom: -100%;
                @media #{$smlg-device} {
                    left: -50px;
                }
                @media #{$md-layout} {
                   display: none; 
                }
                @media #{$sm-layout} {
                   display: none; 
                }
            }
            .img-right-b{
                position: absolute;
                right: 50px;
                bottom: -100%;
                @media #{$smlg-device} {
                    right: -50px;
                }
                @media #{$md-layout} {
                    display: none; 
                }
                @media #{$sm-layout} {
                    display: none; 
                }
            }
        }
    }
}

.g-140{
    --bs-gutter-y: 140px;
    --bs-gutter-x: 140px;
    @media #{$md-layout} {
        --bs-gutter-y: 40px;
        --bs-gutter-x: 40px;
    }
    @media #{$sm-layout} {
        --bs-gutter-y: 30px;
        --bs-gutter-x: 30px;
    }
}


.single-wedding-start{
    p.disc{
        width: 73%;
        @media #{$large-mobile} {
            width: 100%;
        }
    }
}

.single-wedding-start{
    img{
        @media #{$md-layout} {
            width: 100%;
        }
    }
}

.wedding-page{
    .rts-gallery-area-main{
        .gallery-wrapper.main{
            .inner{
                top: 0;
                left: 50%;
                width: auto;
                span{
                    left: 44%;
                    bottom: auto;
                }
            }
        }
    }
}