

// booking area style

.booking-page-top-left{
    .booking-time-line{
        align-items: flex-start;
        @media #{$large-mobile} {
            flex-direction: column;
            align-items: flex-start;
            gap: 30px;
        }
        .right{
            a.number{
                margin-bottom: 10px;
                display: block;
            }
            .add{
                color: #6F7073;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 8px;
                br{
                    @media #{$large-mobile} {
                        display: none;
                    }
                }
            }
            .mail{
                color: #707070;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
            }
        }
    }
}