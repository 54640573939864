// course details

.course-details{
    .large-img{
        width: 100%;
    }
    .title{
        color: #111;
    }
}


.contact-form-main-wrapper-course-details{
    text-align: center;
    max-width: 740px;
    margin: auto;
    @media #{$md-layout} {
        max-width: 100%;
    }
    @media #{$sm-layout} {
        max-width: 100%;
    }
    .title{
        color: #111;
        text-align: center;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        text-transform: capitalize;
    }
    form{
        margin-top: 60px;
        input{
            height: 50px;
            background: #F6F6F6;
            margin-bottom: 30px;
            padding: 10px 20px;
        }
        textarea{
            height: 100px;
            background: #F6F6F6;
            padding: 15px 20px;
        }
        button{
            margin-top: 30px;
        }
    }
}


// corse details card appoinment available

.available-appoinment-card{
    padding: 30px;
    text-align: center;
    border: 1px solid #D5C9B2;
    @media #{$large-mobile} {
        padding: 10px;
    }
    .title-main{
        color: #000;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
    }
    .single-appoinemnt-booking{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 20px;
        margin-bottom: 20px;
        border-bottom: 1px solid #D5C9B2;
        &:nth-child(3){
            border: none;
            margin-bottom: 0;
            padding-bottom: 0;
        }
        .left{
            .title{
                color: #000;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                text-transform: capitalize;
                margin-bottom: 8px;
            }
            .icon{
                display: flex;
                align-items: center;
                gap: 12px;
                i{
                    color: #9C9C9C;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px;
                }
                span{
                    color: #9C9C9C;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px;
                }
            }
        }
        .right{
            .rts-btn{
                padding: 12px 35px !important;
                transition: .3s;
                border: 1px solid transparent;
                height: 42px;
                @media #{$md-layout} {
                    padding: 17px 35px !important;
                }
                @media #{$sm-layout} {
                    padding: 17px 35px !important;
                }
                &:hover{
                    background: transparent;
                    border: 1px solid var(--color-primary);
                    color: var(--color-primary);
                }
            }
        }
    }
}

.booking-page-top-left{
    .thumbnail{
        img{
            width: 100%;
        }
    }
}