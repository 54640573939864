// **************************************** =========== Start Service Scss  ===========  ****************************************//

.rts-team-area{
    @media(max-width:1366px){
        padding-left: 10px;
        padding-right: 10px;
    }
    .section-inner{
        .rts-section-title{
            .heading-title{
                margin: auto;
                margin-bottom: 30px;
                max-width: 50%;
            }
            .desc{
                max-width: 60%;
                margin: auto;
                margin-bottom: 30px;
                @media(max-width:1200px){
                    max-width: 85%;
                }
                @media #{$large-mobile} {
                    max-width: 100%;
                }
            }
            
        }
        .slider-area{
            position: relative;
        }
        .team-wrapper{
            .image-area{
                position: relative;
                z-index: 1;
                overflow: hidden;
                transition: all .3s;
                img{
                    width: 100%;
                }
                &:hover{
                    .social-wrapper{
                        right: 20px;
                    }
                }
                &::before{
                    content: '';
                    position: absolute;
                    width: 100%;
                    height: 100%;
                    background: linear-gradient(180deg, rgba(17, 17, 17, 0.00) 33.53%, #111 100%);
                    top: 0;
                    left: 0;
                    z-index: 0;
                }
                .content{
                    position: absolute;
                    width: max-content;
                    bottom: 30px;
                    left: 50%;
                    transform: translateX(-50%);
                    text-align: center;
                    .title{
                        color: var(--color-white);
                        font-size: 24px;
                        line-height: 34px;
                        margin-bottom: 5px;
                    }
                    p{
                        color: var(--color-white);
                        font-weight: 400;
                        text-transform: capitalize;
                    }

                }
                .social-wrapper{
                    position: absolute;
                    top: 20px;
                    right: -30px;
                    padding: 0;
                    margin: 0;
                    transition: all .3s;
                    
                    li{
                        list-style: none;
                        margin-bottom: 8px;
                        a{
                            width: 30px;
                            height: 30px;
                            line-height: 30px;
                            background: var(--color-white);
                            color: var(--color-primary);
                            display: block;
                            text-align: center;
                            font-size: 14px;
                            transition: all .3s;
                            &:hover{
                                color: var(--color-white);
                                background: var(--color-primary);
                            }
                        }
                    }
                }
            }
        }
        .swiper-progress{
            bottom: -10px;
            top: unset;
            z-index: 1;
            background: #E5E5E5;
            .swiper-pagination-progressbar-fill{
                background: var(--color-primary);
            }
        }
        .team-btn-area{
            .rts-btn{
                margin: auto;
                margin-top: 50px;
            }
        }
    }
}



.rts-single-coach-area{
    .img-thumb{
        position: relative;
        display: block;
        overflow: hidden;
        width: 100%;
        .thumbnail{
            img{
                width: 100%;
                transition: .3s;
            }
        }
        .social-team{
            list-style: none;
            display: flex;
            align-items: center;
            gap: 15px;
            position: absolute;
            left: 50%;
            bottom: -50px;
            transform: translateX(-50%);
            padding: 0;
            margin: 0;
            transition: .3s;
            li{
                padding: 0;
                margin: 0;
                a{
                    width: 40px;
                    height: 40px;
                    display: flex;
                    background: var(--color-primary);
                    align-items: center;
                    justify-content: center;
                    i{
                        color: #fff;
                    }
                }
            }
        }
    }
    .inner-content{
        margin-top: 20px;
        .title{
            color: #111;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 34px;
            text-transform: capitalize;
            margin-bottom: 3px;
        }
        .name{
            color: #6F7073;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 34px;
            text-transform: capitalize;
        }
    }
    &:hover{
        .social-team{
            bottom: 50px;
        }
    }
}

.bg_light-little{
    background: #F6F6F6;
}

.team-details-right-top-conent{
    .single-experience{
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 25px;
        .info{
            .title{
                color: #111;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 2px;
            }
        }
    }
}
.single-about-skill-inner-team-details{
    .title{
        margin-bottom: 55px;
        color: #111;
        font-size: 34px;
        font-style: normal;
        font-weight: 700;
        line-height: 44px;
        text-transform: capitalize;
        position: relative;
        padding-bottom: 15px;
        &::after{
            content: "";
            position: absolute;
            bottom: -20px;
            left: 0;
            width: 100%;
            height: 1px;
            background: #F1F1F1;
        }
    }
    .progress-wrapper-area-two.team-details{
        margin-top: 50px;
        .single-progress{
            margin-bottom: 30px;
            .top{
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-bottom: 15px;
                span{
                    color: #111;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 26px;
                    &.digit{
                        color: #A78448;
                        text-align: right;
                        font-size: 16px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 26px;
                        letter-spacing: -0.32px;
                    }
                }
            }
            .progress{
                height: 6px;
                border-radius: 0;
                .progress-bar{
                    background-color: var(--color-primary);
                }
            }
        }
    }
}

.education-skill-profeccional-area{
    .single-experience{
        display: flex;
        align-items: center;
        gap: 15px;
        margin-bottom: 35px;
        .info{
            .title{
                color: #111;
                font-size: 20px;
                font-style: normal;
                font-weight: 400;
                line-height: 26px;
                margin-bottom: 2px;
                margin-bottom: 5px;
                padding-bottom: 0;
                &::after{
                    display: none;
                }
            }
            span{
                &::after{
                    display: none;
                }
            }
        }
    }
}

.booking-time-line{
    display: flex;
    align-items: center;
    gap: 60px;
    .title{
        color: #111;
        font-size: 18px;
        font-style: normal;
        font-weight: 400;
        line-height: 28px;
        margin-bottom: 10px;
    }
    span{
        color: #707070;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 26px;
        margin-bottom: 15px;
        display: block;
    }
}

.golfy-social-media-with-line{
    display: flex;
    align-items: center;
    .tag{
        color: #707070;
        font-size: 16px;
        font-style: normal;
        font-weight: 700;
        line-height: 26px;
        text-transform: capitalize;
        margin-bottom: 0;
        padding-right: 30px;
        position: relative;
        &::after{
            content: "";
            position: absolute;
            width: 16px;
            height: 1px;
            background: #111;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
        }
    }
    a{
        font-size: 16px;
        border-radius: 50%;
        text-align: center;
        margin: 0 12px;
        transition: all 0.3s ease;
        position: relative;
        color: var(--color-primary);
    }
}

.thumbnail-team-details-left{
    img{
        width: 100%;
    }
}