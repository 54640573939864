.rts-contact-area{
    .section-inner{
        display: flex;
        @media(max-width:991px){
            display: block;
        }
        .map-area{
            width: 50%;
            flex: 1;
            @media(max-width:991px){
                width: 100%;
                height: 500px;
            }
            iframe{
                height: 100%;
            }
        }
        .contact-form-wrapper{
            width: 50%;
            padding: 80px 80px 65px 80px;
            @media(max-width:991px){
                width: 100%;
            }
            @media(max-width:768px){
                padding: 60px 25px;
            }
            .rts-section-title{
                max-width: 60%;
                margin-bottom: 50px;
                @media(max-width:1600px){
                    max-width: 70%;
                }
                @media(max-width:1400px){
                    max-width: 90%;
                }
                @media(max-width:1200px){
                    max-width: 100%;
                }
                .heading-title{
                    font-weight: 400;
                    text-transform: none;
                    span{
                        font-weight: 700;
                    }
                }
            }
            .form-inner{
                max-width: 60%;
                @media(max-width:1600px){
                    max-width: 70%;
                }
                @media(max-width:1400px){
                    max-width: 90%;
                }
                @media(max-width:1200px){
                    max-width: 100%;
                }
                form{
                    .golfy-contact-input-flexing{
                        margin-bottom: 30px;
                        label{
                            color: #141414;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            margin-bottom: 15px;
                        }
                        input{
                            padding: 15px 20px;
                            background: var(--color-white);
                            border: 1px solid var(--color-white);
                            color: var(--color-title);
                            &::placeholder{
                                color: #74787C !important;
                                font-size: 14px !important;
                                font-weight: 400 !important;
                                line-height: normal !important;
                            }
                        }
                    }
                    .golfy-contact-texting-fild{
                        label{
                            color: #141414;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                            margin-bottom: 15px;
                        }
                        textarea{
                            padding: 15px 20px;
                            background: var(--color-white);
                            border: 1px solid var(--color-white);
                            min-height: 100px;
                            color: var(--color-title);
                            &::placeholder{
                                color: #74787C !important;
                                font-size: 14px !important;
                                font-weight: 400 !important;
                                line-height: normal !important;
                            }
                        }  
                    } 
                    button{
                        color: var(--color-white);
                        font-size: 16px;
                        font-weight: 500;
                        line-height: 1;
                        text-transform: capitalize;
                        max-width: max-content;
                        padding: 18px 27px;
                        background: var(--color-primary);
                        margin-top: 30px;
                        transition: all .3s;
                        &:hover{
                            background: #a78448e0;
                        }
                    }  
                }
            }
        }
    }
}


.contact-right-information{
    .contact-info-wrapper{
        display: flex;
        align-items: center;
        gap: 70px;
        @media #{$small-mobile} {
            flex-direction: column;
            align-items: flex-start;
            gap: 30px;
        }
        .single{
            i{
                display: block;
                font-size: 36px;
                margin-bottom: 24px;
                color: var(--color-primary);
            }
            a{
                display: block;
            }
        }
    }
}

.rts-contact-area{
    &.inner{
        iframe{
            filter: grayscale(1);
        }
    }
}