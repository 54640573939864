:root{
    --calendar-border:#D5C9B2;
    --calendar-bg:#fff;
    --calendar-standout:rgb(40,40,59);
    --calendar-color:#A5A5A5;
    --calendar-fade-color:#c0c0c0;
}

.calender-real-time{
    position: relative;
    #btnPrev,
    #btnNext{
        position: absolute !important;
        max-width: max-content;
        top: 22px;
        color: #000 !important;
    }
    #btnPrev{
        left: 15px;
    }
    #btnNext{
        right: 15px;
    }
    .days{
        td{
            color: #707070;
        }
    }
    .group {
      &:after {
        content: "";
        display: table;
        clear: both;
      }
    }
    img {
      max-width:100%;
      height:auto;
      vertical-align:baseline;
    }
    
    a {
      text-decoration:none;
    }
    .calendar-wrapper {
      border-radius:5px;
      background:var(--calendar-bg);
      
    }
    table {
      clear:both;
      width:100%;
      border:1px solid var(--calendar-border);
      border-radius:3px;
      border-collapse:collapse;
      color: var(--calendar-color);
    }
    td {
      height:60px;
      text-align:center;
      vertical-align:middle;
      border-right:1px solid var(--calendar-border);
      border-top:1px solid var(--calendar-border);
      width:100% / 7;
      @media #{$large-mobile} {
        padding: 0;
      }
    }
    td.not-current {
      color:var(--calendar-fade-color);
    }
    td.today {
      font-weight:700;
      color: var(--calendar-standout);
      font-size:1.5em;
      background: #C9A361;
      color: #fff;
      
    }
    td.normal{
        background: var(--color-primary) !important;
        color: #fff;
    }
    thead td {
      border:none;
      color: var(--calendar-standout);
      text-transform:capitalize;
      font-size: 24px;
      background: #FFF7E9;
      font-family: var(--title-font);
    }
    #btnPrev {
      float:left;
      margin-bottom:20px;
    //   &:before {
    //     content:'\f104';
    //     padding-right:4px;
    //     font-family: "Font Awesome 6 Pro";
    //   }
    }
    #btnNext {
      float:right;
      margin-bottom:20px;
    //   &:after {
    //     content:'\f105';
    //     padding-left:4px;
    //   }
    }
    #btnPrev, #btnNext {
      background:transparent;
      border:none;
      outline:none;
      font-size:1em;
      color:var(--calendar-fade-color);
      cursor:pointer;
      text-transform:uppercase;
      transition:all 0.3s ease;
      &:hover {
        color: var(--calendar-standout);
        font-weight:bold;
      }
    }

}


.course-success{
  #customers {
    font-family: Arial, Helvetica, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #E6E6E6;
  }
  
  #customers td, #customers th {
    padding: 8px;
  }
  
  #customers tr:nth-child(even){
    background-color: #FFFFFF;
    border-bottom: 1px solid #E6E6E6 !important;
  }
  #customers tr:nth-child(odd){
    background-color: #FFFFFF;
    border-bottom: 1px solid #E6E6E6 !important;
  }
  

  
  #customers th {
    padding-top: 12px;
    padding-bottom: 12px;
    text-align: left;
    background-color: var(--color-primary);
    color: white;
  }

  .rts-btn{
    margin: auto;
  }
  td{
    padding: 10px 16px !important;
  }

}



.course-details-left-wrapper{
  .small-image-para-wrapper{
    display: flex;
    align-items: center;
    gap: 30px;
    @media #{$large-mobile} {
      flex-direction: column;
      align-items: flex-start;
      img{
        width: 100%;
      }
    }
  }
}