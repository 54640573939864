// **************************************** =========== Start Service Scss  ===========  ****************************************//

.rts-service-area{
    @media(max-width:1366px){
        padding-left: 10px;
        padding-right: 10px;
    }
    .service-inner{
        .service-wrapper{
            position: relative;
            .overlink{
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                z-index: 2;
            }
            .image-area{
                position: relative;
                z-index: 10;
                overflow: hidden;
                transition: all .6s;
                &:hover{
                    .bottom-content{
                        bottom: 40px;
                        @media(max-width:1024px) and (min-width:991px){
                            bottom: 20px;
                        }
                    }
                }
                a{
                    img{
                        width: 100%;
                        transform: scale(100%);
                    }
                }
                &::before{
                    content: '';
                    position: absolute;
                    background: linear-gradient(180deg, rgba(17, 17, 17, 0.00) 0%, #111 94.71%);
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    z-index: 1;
                }
                .bottom-content{
                    position: absolute;
                    width: 80%;
                    height: max-content;
                    left: 40px;
                    bottom: -90px;
                    transition: all .6s;
                    z-index: 1;
                    @media(max-width:1200px) and (min-width:991px){
                        width: 93%;
                        left: 10px;
                    }
                    @media(max-width:768px){
                        left: 25px;
                    }
                    .icon{
                        width: max-content;
                    }
                    .title{
                        color: var(--color-white);
                        font-size: 25px;
                        font-weight: 400;
                        line-height: 32px;
                        text-transform: capitalize;
                        margin-top: 30px;
                        margin-bottom: 15px;
                        @media(max-width:1366px) and (min-width:991px){
                            font-size: 22px;
                        }
                        @media(max-width:1200px) and (min-width:991px){
                            margin-top: 15px;
                            margin-bottom: 12px;
                        }
                        @media(max-width:768px){
                            font-size: 20px;
                        }
                        @media(max-width:576px){
                            margin-bottom: 30px;
                        }
                    }
                    .desc{
                        color: var(--color-white);
                        font-size: 14px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: 24px; 
                        text-transform: capitalize;
                        margin-bottom: 20px;
                        @media(max-width:1200px) and (min-width:991px){
                            margin-bottom: 20px;
                        }
                    }
                    .service-btn{
                        color: var(--color-white);
                        font-family: var(--title-font);
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 26px;
                        text-transform: capitalize;
                        display: flex;
                        align-items: center;
                        i{
                            margin-left: 8px;
                        }
                    }
                }
            }
        }
    }
    &.area-2{
        .service-inner{
            .left-side-content{
                .rts-section-title{
                    .heading-title{
                        margin-bottom: 20px;
                    }
                    .desc{
                        max-width: 80%;
                    }
                } 
                .service-wrapper{
                    margin-top: 80px;
                    ul{
                        padding: 0;
                        margin: 0;
                        display: flex;
                        align-items: center;
                        flex-wrap: wrap;
                        gap: 50px;
                        li{
                            display: flex;
                            gap: 22px;
                            align-items: center;
                            width: 45%;
                            margin: 0;
                            @media(max-width:1200px) and (min-width:991px){
                                gap: 15px;
                                width: 43%;
                            }
                            @media(max-width:576px){
                                width: 100%;
                            }
                            .icon{
                                flex-basis: 20%;
                                @media(max-width:576px){
                                    flex-basis: auto;
                                }
                            }
                            .service-title{
                                flex-basis: 80%;
                                color: var(--color-title);
                                font-size: 20px;
                                font-weight: 400;
                                line-height: 30px;
                                text-transform: capitalize;
                                margin: 0;
                                @media(max-width:1200px) and (min-width:991px){
                                    font-size: 16px;
                                }
                                @media(max-width:991px){
                                    br{
                                        display: none;
                                    }
                                }
                            }
                        }
                    }
                }  
            }
            .right-side-hero{
                margin-left: 30px;
                @media(max-width:991px){
                    margin-left: 0;
                    margin-bottom: 60px;
                }
            }
        }
        .service-bottom{
            margin-top: 100px;
            @media(max-width:1366px){
                margin: 100px 15px 0 15px;
            }
            .counter-up-area{
                display: flex;
                align-items: center;
                justify-content: space-between;
                @media(max-width:1200px){
                    gap: 60px;
                    justify-content: left;
                }
                .rts-single-service-two{
                    display: flex;
                    align-items: center;
                    gap: 25px;
                    .counter-wrapper{
                        .title-main{
                            color: var(--color-title);
                            font-size: 48px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 50px;
                            text-transform: capitalize;
                            margin-bottom: 5px;
                        }
                    }
                }
            }
        }
    }
    &.home-2{
        .rts-section-title{
            max-width: 45%;
            margin: auto;
            @media #{$sm-layout} {
                max-width: 100%;
            }
        }
        .service-wrapper{
            @media(max-width:991px){

            }
        }
    }
}

.rts-match-event-area{
    @media(max-width:1366px){
        padding-left: 10px;
        padding-right: 10px;
    }
    .rts-section-title{
        max-width: 50%;
        margin: 0 auto 80px auto;
        @media(max-width:1200px){
            max-width: 60%;
        }
        @media(max-width:768px){
            max-width: 80%;
        }
        @media(max-width:576px){
            max-width: 100%;
            .heading-title{
                font-size: 32px;
                line-height: 45px;
            }
        }
        @media(max-width:450px){
            .heading-title{
                font-size: 24px;
                line-height: 40px;
            }
        }
    }
    .section-inner{
       .event-wrapper{
            display: flex;
            align-items: center;
            padding: 60px 0;
            border-bottom: 1px solid #D9D9D9;
            transition: all .4s;
            @media(max-width:768px){
                display: block;
            }
            &:hover{
                .image-area{
                    img{
                        transform: scale(110%);
                    }
                }
            }
            &:first-child{
                padding-top: 0;
            }
            &:last-child{
                border: none;
                padding-bottom: 0;
            }
            .image-area{
                flex-basis: 20%;
                overflow: hidden;
                @media(max-width:768px){
                    margin-bottom: 30px;
                }
                img{
                    transform: scale(100%);
                    transition: all .4s;
                    @media(max-width:768px){
                        width: 100%;
                    }
                }
            }
            .content{
                flex-basis: 40%;
                padding-left: 60px;
                @media(max-width:1200px){
                    padding-left: 30px;
                }
                @media(max-width:991px){
                    flex-basis: 45%;
                    padding-left: 20px;
                }
                @media(max-width:768px){
                    padding-left: 0;
                    margin-bottom: 30px;
                }
                .event-single{
                    display: flex;
                    align-items: center;
                    gap: 30px;
                    margin-bottom: 20px;
                    @media(max-width:991px) and (min-width:768px){
                        gap: 15px;
                        margin-bottom: 10px;
                    }
                    @media(max-width:450px){
                        display: block;
                    }
                    p{
                        margin-bottom: 0;
                        img{
                            margin-right: 10px;
                        }
                        &.location{
                            color: var(--color-title);
                            font-weight: 500;
                            @media(max-width:450px){
                                margin-bottom: 10px;
                            }
                        }
                        @media(max-width:991px) and (min-width:768px){
                            font-size: 14px;
                        }
                    }
                }
                .event-title{
                    color: var(--color-title);
                    font-size: 26px;
                    font-weight: 400;
                    line-height: 32px;
                    text-transform: capitalize;
                    margin-bottom: 0;
                    max-width: 70%;
                    @media(max-width:1200px){
                        max-width: 80%;
                    }
                    @media(max-width:991px) and (min-width:768px){
                        font-size: 22px;
                        line-height: 30px;
                    }
                    @media(max-width:768px){
                        max-width: 100%;
                    }
                    a{
                        font-family: var(--title-font);
                    }
                }
            }
            .ticket-area{
                flex-basis: 20%;
                justify-content: center;
                display: flex;
                align-items: center;
                gap: 18px;
                @media(max-width:991px) and (min-width:768px){
                    flex-basis: 17%;
                    gap: 10px;
                    justify-content: left;
                }
                @media(max-width:768px){
                    justify-content: left;
                    margin-bottom: 30px;
                }
                .icon{
                    @media(max-width:450px){
                        width: 40px;
                    }
                }
                .price{
                    color: var(--color-title);
                    font-size: 32px;
                    font-weight: 400;
                    line-height: 42px;
                    text-transform: capitalize;
                    margin-bottom: 0;
                    @media(max-width:991px) and (min-width:768px){
                        font-size: 24px;
                        line-height: 34px;
                    }
                    @media(max-width:450px){
                        font-size: 24px;
                        line-height: 34px;
                    }
                }
            }
            .button-area{
                flex-basis: 20%;
                text-align: end;
                @media(max-width:991px) and (min-width:768px){
                    flex-basis: 18%;
                    .rts-btn{
                        padding: 18px 25px;
                    }
                }
                .rts-btn{
                    margin-left: auto;
                    @media(max-width:768px){
                        margin-left: 0;
                        margin-right: auto;
                    }
                }
            }
        }
    }
}

.rts-testimonial-section{

    .rts-section-title{
        max-width: 40%;
        margin: 0 auto 60px auto;
        @media(max-width:1200px){
            max-width: 50%;
        }
        @media #{$sm-layout} {
            max-width: 100%;
        }
        .heading-title{
            font-weight: 400;
        }
    }
    .section-inner{
        position: relative;
        @media(max-width:1366px){
            margin: 0 15px;
        }
        .testimonial-wrapper{
            .desc{
                max-width: 60%;
                margin: auto;
                margin: 40px auto;
                font-size: 20px;
                line-height: 30px;
                @media(max-width:991px){
                    max-width: 80%;
                }
                @media(max-width:576px){
                    max-width: 100%;
                }
            }
        }
        .swiper-button-area{
            display: block;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            .swiper-button-prev,
            .swiper-button-next{
                &::after{
                    display: none;
                }
                width: 50px;
                height: 50px;
                line-height: 50px;
                border-radius: 50%;
                background: var(--color-white);
                color: var(--color-title);
                transition: all .3s;
                &:hover{
                    background: var(--color-primary);
                    color: var(--color-white);
                }
                @media(max-width:576px){
                    display: none;
                }
            }
            .swiper-button-prev{
                left: 0;
                @media(max-width:1366px){
                    left: 15px;
                }
            }
            .swiper-button-next{
                right: 0;
                @media(max-width:1366px){
                    right: 15px;
                }
            }
        }
        .swiper-pagination3{
            position: relative;
            width: 100px;
            text-align: center;
            margin: 60px auto 0 auto;
            .swiper-pagination-bullet{
                border: 1px solid var(--color-primary);
                opacity: 1;
                background: none;
                width: 12px;
                height: 12px;
                position: relative;
                transition: all .3s;
                &:hover{
                    &::after{
                        opacity: 1;
                    }
                }
                &::after{
                    content: '';
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    top: 50%;
                    background: var(--color-primary);
                    opacity: 0;
                    transition: all .3s;
                }
                &.swiper-pagination-bullet-active{
                    &::after{
                        opacity: 1;
                    }
                }
            }
        }
    }
    &.home-3{
        .section-inner{
            .testimonial-wrapper{
                border-radius: 6px;
                .image-area{
                    position: relative;
                    border-radius: 6px;
                    z-index: 1;
                    overflow: hidden;
                    transition: all .6s;
                    &:hover{
                        &::after{
                            transform: translateY(0);
                        }
                        &::before{
                            transform: translateY(-100%);
                        }
                        .quote{
                            color: var(--color-white);
                        }
                        .author-area{
                            .author-content{
                                .title{
                                    color: var(--color-white);
                                }
                                .author-desc{
                                    color: #ccc;
                                }
                            }
                        }
                    }
                    img{
                        border-radius: 9px;
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        border-radius: 6px;
                        background: rgba(17, 17, 17, 0.65);
                        z-index: 0;
                        transform: translateY(101%);
                        transition: all .6s;
                    }
                    &::before{
                        content: '';
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        border-radius: 6px;
                        background: #F6F6F6;
                        z-index: 0;
                        transition: all .6s;
                        transform: translateY(0);
                    }
                    .testimonial-content{
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        top: 0;
                        left: 0;
                        padding: 40px 50px 40px 40px;
                        z-index: 1;
                    }
                }
                ul.star-rating{
                    padding: 0;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    gap: 6px;
                    li{
                        list-style: none;
                        color: #FFB341;
                    }
                }
                .quote{
                    color: #000;
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 30px;
                    text-transform: capitalize;
                    transition: all .6s;
                }
                .author-area{
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    .author-content{
                        .title{
                            color: #000;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 30px;
                            text-transform: capitalize;
                            margin-bottom: 0;
                            transition: all .6s;
                        }
                        .author-desc{
                            color: #707070;
                            font-size: 14px;
                            font-weight: 400;
                            line-height: 26px;
                            text-transform: capitalize;
                            transition: all .6s;
                        }
                    }
                }
                .icon{
                    position: absolute;
                    top: 30px;
                    right: 30px;
                    z-index: 1;
                }
            }
            .swiper-pag{
                text-align: center;
                margin-top: 50px;
                .swiper-pagination-bullet{
                    border: 1px solid var(--color-primary);
                    opacity: 1;
                    background: none;
                    width: 12px;
                    height: 12px;
                    position: relative;
                    transition: all .3s;
                    &:hover{
                        &::after{
                            opacity: 1;
                        }
                    }
                    &::after{
                        content: '';
                        position: absolute;
                        width: 7px;
                        height: 7px;
                        border-radius: 50%;
                        transform: translate(-50%, -50%);
                        left: 50%;
                        top: 50%;
                        background: var(--color-primary);
                        opacity: 0;
                        transition: all .3s;
                    }
                    &.swiper-pagination-bullet-active{
                        &::after{
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}

.rts-pricing-area{
    @media(max-width:1366px){
        padding-left: 10px;
        padding-right: 10px;
    }
    .rts-section-title{
        max-width: 42%;
        margin: 0 auto 60px auto;
        @media(max-width:1200px){
            max-width: 55%;
        }
        @media #{$sm-layout} {
            max-width: 100%;
        }
        .heading-title{
            font-weight: 400;
        }
    }
    .section-inner{
        .pricing-wrapper-one{
            padding: 40px 50px 50px 40px;
            border: 1px solid #E3E3E3;
            margin: 0 30px;
            @media(max-width:1200px){
                margin: 0;
            }
            @media(max-width:768px){
                padding: 40px 30px 50px 30px;
            }
            .pricing-header-start{
                margin-bottom: 30px;
                .title{
                    color: var(--color-title);
                    font-size: 20px;
                    font-weight: 400;
                    line-height: 24px;
                }
                .price-area{
                    .title{
                        color: var(--color-title);
                        font-size: 48px;
                        font-weight: 700;
                        line-height: 50px;
                        text-transform: capitalize;
                        margin-bottom: 10px;
                    }
                    span{
                        color: #6F7073;
                        font-size: 16px;
                        font-weight: 400;
                        line-height: 1.2;
                        text-transform: lowercase;
                    }
                }
            }
            .pricing-body{
                padding-top: 30px;
                border-top: 1px solid #D8D8D8;
                .description{
                    margin-bottom: 25px;
                    .disc{
                        line-height: 26px
                    }
                }
                .single-pricing{
                    display: flex;
                    align-items: center;
                    gap: 10px;
                    margin-bottom: 10px;
                    i{
                        color: var(--color-primary);
                    }
                }
                .rts-btn{
                    margin-top: 40px;
                    height: 40px;
                    line-height: 40px;
                    padding: 0 40px;
                    color: var(--color-primary);
                    background: none;
                    border: 1px solid var(--color-primary);
                    transition: all .3s;
                    &:hover{
                        color: var(--color-white);
                        background: var(--color-primary);
                    }
                }
            }
            &.mid{
                background: #FFF4E1;
                border: 1px solid #FFF4E1;
            }
        }
    }
    &.home-2{
        .section-inner{
            .faq-area{
                .rts-section-title{
                    max-width: 70%;
                    margin: 0;
                    @media(max-width:1200px) and (min-width:991px){
                        max-width: 90%;
                    }
                    @media(max-width:768px){
                        max-width: 100%;
                    }
                }
                .accordion-two{
                    margin-right: 60px;
                    @media(max-width:991px){
                        margin-right: 0;
                    }
                    .accordion-item{
                        background: none;
                        border-width: 1px 0 0 0;
                        border-style: solid;
                        border-color: #E3E3E3;
                        padding: 21px 0;
                        .accordion-button{
                            background: none;
                            border: none;
                            box-shadow: none;
                            font-size: 22px;
                            padding: 0;
                            color: var(--color-title);
                            &::after{
                                background: none;
                                content: '\2b';
                                transform: none;
                                font-family: 'Font Awesome 6 Pro';
                                font-weight: 300;
                                font-size: 16px;
                            }
                        }
                        &:last-child{
                            border-bottom: 1px solid #E3E3E3;
                        }
                        .accordion-button:not(.collapsed)::after{
                            content: '\f068';
                        }
                        .accordion-body{
                            padding: 1rem 0;
                        }
                    }
                }
            }
        }
        .pricing-inner{
            .pricing-wrapper-one{
                margin: 0;
                border: none;
                @media(max-width:1200px){
                    padding: 40px 20px 50px 20px;
                }
                h5.title{
                    border: 1px solid var(--color-primary);
                    width: max-content;
                    padding: 8px 12px;
                    border: 1px solid var(--color-primary);
                    color: var(--color-primary);
                    font-size: 14px;
                    line-height: 1;
                }
                &.mid{
                    background: #FFFBF3;
                }
            } 
        }
    }
    &.home-3{
        .container{
            max-width: 740px;
        }
        .section-inner{
            .faq-area{
                .rts-section-title{
                    max-width: 100%;
                    margin: 0 auto;
                }
                .accordion-two{
                    margin-right: 0;
                    .accordion-item{
                        border-color: #d5c9b259;
                        .accordion-button{
                            font-size: 22px;
                            &::after{
                                color: #d5c9b296;
                            }
                        }
                        .accordion-body{
                            .disc{
                                max-width: 88%;
                            }
                        }
                        &:first-child{
                            border-top: none;
                        }
                        &:last-child{
                            border-bottom: 1px solid #d5c9b296;
                        }
                    }
                }
            }
        }
    }
}
.rts-video-area{
    .section-inner{
        video{
            min-width: 100%;
            height: 650px;
            object-fit: cover;
        }
    }
}

.facility-details-step-2 {
    p {
        &.disc {
            font-size: 16px;
            line-height: 26px;
        }
    }
    &.inner{
        max-width: 900px;
        margin: 0 auto;
    }
}

.facility-details-step-1 {
    .thumbnail {
        margin-bottom: 45px;
    }

    .title {
        margin-bottom: 16px;
    }

    p {
        &.disc {
            margin-bottom: 23px;
            font-size: 16px;
            line-height: 26px;
        }
    }

    .facility-details-card {
        padding: 30px;
        box-shadow: 0px 8px 30px #6a6a6a1a;
        display: flex;
        align-items: flex-start;
        border-left: 5px solid var(--color-primary);

        .thumbnail {
            img {
                height: 29px;
                width: 74px;
            }
        }

        .details {
            margin-left: 20px;

            .title {
                margin-bottom: 5px;
                font-size: 20px;
            }

            p {
                margin-bottom: 0;
            }
        }
    }
}

.facility-details-step-2 {

    // single step
    .single-facility-step {
        background: #fff;
        padding: 40px 20px;
        border: 1px solid #EEEEEE;
        box-shadow: 0px 6px 39px #0000000a;

        p {
            &.step {
                position: relative;
                color: #fff;
                z-index: 1;
                margin-bottom: 52px;
                font-weight: 700;
                font-size: 20px;
                line-height: 28px;
                margin-top: 29px;

                &::after {
                    position: absolute;
                    content: '';
                    left: 50%;
                    top: 50%;
                    background: #DF0A0A;
                    height: 60px;
                    width: 60px;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                    border-radius: 50%;
                }

                &::before {
                    position: absolute;
                    content: '';
                    left: 50%;
                    top: 50%;
                    background: #e81c2e1a;
                    height: 90px;
                    width: 90px;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                    border-radius: 50%;
                }
            }
        }

        .title {
            margin-bottom: 8px;
        }

        p {
            &.disc {
                font-size: 16px;
                line-height: 26px;
            }
        }
    }
}

.facility-details-step-3 {
    .title {
        margin-bottom: 8px;
    }

    p {
        &.disc {
            font-size: 16px;
            line-height: 26px;
            margin-bottom: 20px;
        }
    }

    .single-banifits {
        margin-bottom: 10px;

        i {
            margin-right: 15px;
            color: var(--color-primary);
        }
    }
}

.rts-single-wized{
    background: #F6F6F6;
    border-radius: 15px;
    padding: 40px;
    margin-bottom: 40px;
    &:last-child{
        margin-bottom: 0;
    }
    @media #{$small-mobile} {
        padding: 20px;
    }
    &.service{
        border-radius: 0;
        .single-categories{
            li{
                a{
                    border-radius: 0;
                }
            }
        }
    }
    &.download{
        background: #1C2539;
        .title{
            color: #fff;
        }
        .single-download-area{
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 20px 0;
            border-bottom: 1px solid #2E3951;
            &:first-child{ 
                padding-top: 0;
            }
            &:last-child{
                border-bottom: none;
                padding-bottom: 0;
            }
            .mid{
                margin-right: auto;
                margin-left: 15px;
                .title{
                    margin-bottom: 0;
                    font-size: 18px;
                    font-family: var(--font-primary);
                }
                span{
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 18px;
                    color: #FFFFFF;
                }
            }
            a{
                &.rts-btn{
                    padding: 11px 15px;
                    border-radius: 0;
                }
            }
        }
    }
    &.contact{
        background: #1C2539;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 60px;
        @media #{$small-mobile} {
            padding: 25px 20px;
        }
        &:last-child{
            @media #{$small-mobile} {
                margin-bottom: 0;
            }
        }
        .wized-body{
            text-align: center;
            .title{
                color: #fff;
                margin-bottom: 30px;
                font-size: 30px;
                line-height: 40px;
            }
            a{
                &.rts-btn{
                    display: block;
                    max-width: max-content;
                    margin: auto;
                }
            }
            .contact-area{
                padding: 0;
                margin: 0;
                li{
                    list-style: none;
                    color: var(--color-white);
                    font-size: 20px;
                    font-weight: 600;
                    margin: 20px 0;
                    i{
                        margin-right: 7px;
                    }
                }
            }
        }
    }
    .wized-header{
        .title{
            margin-bottom: 10px;
        }
    }
    .wized-body{
        margin-top: 40px;
    }
    .single-categories{
        margin-bottom: 15px;
        padding: 0;
        &:last-child{
            margin-bottom: 0;
        }
        li{
            list-style: none;
            a{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 15px 25px;
                background: #fff;
                color: #5D666F;
                font-weight: 500;     
                transition: .3s;  
                border-radius: 5px;         
                i{
                    color: var(--color-primary);
                    transition: .3s;
                }
                &:hover{
                    background: var(--color-primary);
                    transform: translateY(-5px) scale(1.03);
                    color: var(--color-white);  
                    i{
                        color: #fff;
                    }  
                }
            }
        }
    }
    // recent post
    .recent-post-single{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
        .thumbnail{
            margin-right: 20px;
            overflow: hidden;
            max-width: max-content;
            width: 100%;
            border-radius: 5px;
            img{
                min-width: 85px;
                height: auto;
                transition: .3s;
            }
            &:hover{
                img{
                    transform: scale(1.2);
                }
            }
        }
        .user{
            display: flex;
            align-items: center;
            span{
                margin-left: 9px;
            }
        }
        .post-title{
            .title{
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 600;
                color: #1C2539;
                line-height: 26px;
                margin-top: 5px;
                transition: .3s;
                @media #{$small-mobile} {
                    font-size: 14px;
                    line-height: 26px;
                    margin-top: 0;
                }
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
    }
    // gallery post
    .gallery-inner{
        display: flex;
        flex-direction: column;
        .single-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            a{
                overflow: hidden;
                border-radius: 6px;
                @media #{$small-mobile} {
                    display: block;
                    width: 100%;
                }
                img{
                    max-width: 97px;
                    height: auto;
                    transition: .3s;
                    @media #{$laptop-device} {
                        max-width: 80px;
                    }
                    @media #{$smlg-device} {
                        min-width: 269px;
                    }
                    @media #{$md-layout} {
                        min-width: 193px;
                    }
                    @media #{$sm-layout} {
                        min-width: 135px;
                    }
                    @media #{$large-mobile} {
                        min-width: 140px;
                    }
                    @media #{$small-mobile} {
                        min-width: 80px;
                    }
                }
                &:hover{
                    img{
                        transform: scale(1.2);
                    }
                }
            }
            &.row-1{
                margin-bottom: 20px;
            }
        }
    }
    .tags-wrapper{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;
        a{
            padding: 5px 16px;
            background: #fff;
            border-radius: 5px;
            margin-right: 10px;
            margin-bottom: 10px;
            color: #1C2539;
            font-size: 14px;
            font-weight: 500;
            transition: .3s;
            &:hover{
                background: var(--color-primary);
                color: #fff;
                transform: translateY(-3px) scale(1.09);
            }
        }
    }
}