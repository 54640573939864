

// faq

.faq .rts-pricing-area.home-3 .container{
    max-width: 1290px;
    .accordion-item{
        border-radius: 0 !important;
        padding: 25px 0 !important;
    }
}