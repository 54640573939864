
// cta main wrapper

.call-to-action-small{
    background-image: url(../images/course-details/03.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center, center;
    height: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 30px;
    @media #{$large-mobile} {
        flex-direction: column;
        align-items: flex-start;
        gap: 20px;
    }
    .title{
        margin-bottom: 0;
        color: #FFF;
        font-size: 40px;
        font-style: normal;
        font-weight: 400;
        line-height: 50px;
        text-transform: capitalize;
        @media #{$large-mobile} {
            font-size: 26px;
            line-height: 36px;
        }
    }
    .rts-btn {
        height: max-content;
        background: #fff;
        color: var(--color-primary);
    }
}