// **************************************** =========== Start About Scss  ===========  ****************************************//

.rts-about-area{
    @media(max-width:1366px){
        padding-left: 15px;
        padding-right: 15px;
    }
    .about-image-thumbnail-3{
        position: relative;
        .image-1{
            @media(max-width:768px){
                width: 100%;
            }
        }
        .image-2{
            position: absolute;
            bottom: -75px;
            right: -40px;
            @media(max-width:991px){
                bottom: -60px;
                right: 120px;
            }
            @media(max-width:768px){
                right: 0;
            }
            @media(max-width:576px){
                display: none;
            }
            &::before{
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                background: rgba(17, 17, 17, 0.50);
            }
        }
        .year{
            position: absolute;
            bottom: -90px;
            @media(max-width:576px){
                display: none;
            }
            p{
                color: var(--color-primary);
                font-size: 16px;
                line-height: 50px;
                span{
                    color: var(--color-title);
                    font-size: 50px;
                    font-weight: 700;
                    @media(max-width:1200px) and (min-width:991px) {
                        font-size: 42px;
                    }
                }
            }
        }
    }
    .about-three-wrapper{
        margin-left: 80px;
        @media(max-width:1200px){
            margin-left: 0;
        }
        @media(max-width:991px){
            margin-top: 150px;
        }
        @media(max-width:576px){
            margin-top: 50px;
        }
        .rts-section-title{
            .heading-title{
                max-width: 80%;
                margin-bottom: 25px;
                @media(max-width:1200px) and (min-width:991px) {
                    max-width: 100%;
                    font-size: 36px;
                }
                @media(max-width:768px){
                    max-width: 100%;
                }
                @media(max-width:576px){
                    font-size: 36px;
                }
                @media(max-width:450px){
                    font-size: 24px;
                    line-height: 36px;
                }
            }
        }
        .rts-tab-three-start{
            margin-top: 0;
            ul{
                padding: 0;
                border: none;
                margin:0 0 40px 0;
                @media(max-width:1200px){
                    gap: 10px;
                }
                li{
                    padding: 0;
                    margin: 0;
                    button{
                        background: transparent;
                        font-weight: 500;
                        font-size: 16px;
                        color: #171717;
                        border: 1px solid #F0F0F0;
                        width: 160px;
                        height: 40px;
                        line-height: 40px;
                        padding: 0;
                        text-align: center;
                        border-radius: 0;
                        @media(max-width:450px){
                            width: 120px;
                        }
                        &:hover{
                            background: var(--color-primary);
                            color: var(--color-white);
                        }
                        &[aria-selected="true"]{
                            background: var(--color-primary);
                            color: var(--color-white);
                        }
                    }
                }
            }
            .tab-content{
                .tab-pane{
                    .single-tab-content-three{
                        .disc{
                            color: #707070;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 26px;
                            max-width: 92%;
                            margin-bottom: 60px;
                            @media(max-width:768px){
                                max-width: 100%;
                                margin-bottom: 40px;
                            }
                        }
                    }
                }
            }
        }
    }
    &.home-2{
        .about-image-thumbnail-3{
            img{
                @media(max-width:991px){
                    width: 100%;
                }
            }
        }
       .about-three-wrapper{
            margin-left: 80px;
            @media(max-width:991px){
                margin-left: 0;
                margin-top: 60px;
            }
            .rts-section-title{
                .heading-title{
                    font-weight: 400;
                    @media(max-width:1200px) and (min-width:991px) {
                        max-width: 90%;
                        font-size: 36px;
                    }
                }
                margin-bottom: 50px;
            }
            .desc{
                max-width: 85%;
                margin-bottom: 60px;
                @media(max-width:768px){
                    max-width: 100%;
                }
            }
            .banner-btn-area{
                .rts-btn{
                    padding: 15px 53px;
                }
            }
       } 
    }
    &.home-3{
        .about-image-thumbnail-3{
            display: flex;
            align-items: end;
            gap: 20px;
            .thumbnail-left{
                width: 44%;
                .content{
                    margin-bottom: 20px;
                    .title{
                        color: #262626;
                        font-size: 24px;
                        font-weight: 400;
                        line-height: 50px;
                        text-transform: capitalize;
                        margin-bottom: 10px;
                        @media #{$large-mobile} {
                            font-size: 23px;
                        }
                    }
                    .year{
                        position: unset;
                        font-size: 96px;
                        font-weight: 700;
                        line-height: 50px;
                        text-transform: capitalize;
                        color: rgba(112, 112, 112, 1);
                        -webkit-text-fill-color: transparent;
                        -webkit-text-stroke-width: 1px;
                        -webkit-text-stroke-color: rgba(112, 112, 112, 1);
                    }
                }
            }
            .image-1{
                width: 56%;
            }
        }
        .about-three-wrapper{
            margin-left: 0;
            .rts-section-title{
                .heading-title{
                    max-width: 100%;
                    margin-bottom: 15px;
                }
                .sub-title{
                    margin-bottom: 15px;
                }
            }
            .desc{
                max-width: 80%;
                margin-bottom: 25px;
                @media #{$large-mobile} {
                    max-width: 100%;
                }
            }
            .progress-wrapper-area{
                display: flex;
                align-items: center;
                gap: 25px;
                margin-bottom: 34px;
                @media #{$large-mobile} {
                    flex-direction: column;
                    align-items: flex-start;
                }
                .single-progress{
                    display: flex;
                    width: 50%;
                    align-items: center;
                    background: var(--color-white);
                    padding: 20px 30px;
                    gap: 16px;
                    @media #{$large-mobile} {
                        width: 100%;
                    }
                    .icon{
                        width: 20%;
                    }
                    .progress-right{
                        width: 80%;
                        .top{
                            .name{
                                color: #000;
                                font-size: 18px;
                                font-weight: 400;
                                line-height: 22px;
                                margin-bottom: 10px;
                            }
                        }
                        .bottom{
                            display: flex;
                            align-items: center;
                            gap: 6px;
                            .progress{
                                width: 65%;
                                height: 3px;
                                background: #d9d9d9;
                                .progress-bar{
                                    background: var(--color-primary);
                                }
                            }
                            .digit{
                                color: #000;
                                font-size: 20px;
                                line-height: 30px;
                                margin-bottom: 0;
                            }
                            
                        }
                    }
                }
            }
            .author-area{
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding-top: 34px;
                border-top: 1px solid #D9D9D9;
                @media #{$large-mobile} {
                    flex-direction: column;
                    align-items: flex-start;
                    gap: 20px;
                }
                .author{
                    display: flex;
                    align-items: center;
                    gap: 20px;
                    .content{
                        .title{
                            color: var(--color-title);
                            font-size: 20px;
                            font-weight: 400;
                            line-height: 22px;
                            margin-bottom: 10px;
                        }
                        .author-desc{
                            color: #707070;
                            font-size: 16px;
                            font-weight: 400;
                            line-height: 22px;
                        }
                    }
                }
                .rts-btn{
                    padding: 18px 55px;
                    line-height: 1;
                }
            }
        }
    }
}