
// blog list
.single-blog-list-style-one{
    .thumbnail{
        display: block;
        overflow: hidden;
        img{
            min-width: 100%;
        }
    }
    .blog-body{
        padding: 40px;
        background: #F6F6F6;
        @media #{$sm-layout} {
            padding: 20px;
        }
        .top{
            display: flex;
            align-items: center;
            gap: 30px;
            @media #{$large-mobile} {
                flex-direction: column;
                align-items: flex-start;
                gap: 20px;
            }
            .single{
                display: flex;
                align-items: center;
                gap: 12px;
                i{
                    color: var(--color-primary);
                }
                span{
                    color: #74787C;
                }
            }
        }
        .inner{
            margin-top: 25px;
            .title{
                color: #30373E;
                font-size: 34px;
                font-style: normal;
                font-weight: 700;
                line-height: 44px;
                text-transform: capitalize;
                margin-bottom: 30px;
                transition: .3s;
                @media #{$md-layout} {
                    font-size: 26px;
                    line-height: 36px;
                }
                @media #{$sm-layout} {
                    font-size: 26px;
                    line-height: 36px;
                }
                @media #{$large-mobile} {
                    font-size: 20px;
                    line-height: 34px;
                    br{
                        display: none;
                    }
                }
                &:hover{
                    color: var(--color-primary);
                }
            }
            .rts-btn{
                border-radius: 0;
                padding: 15px 50px;
            }
        }
    }
}



// padding left sidebar 

.padding-left-sidebar {
    padding-left: 60px;
}


.service-left-sidebar-wized{
    padding: 40px;
    background: #F6F6F6;
    .search-area{
        position: relative;
        input{
            width: 100%;
            background: #fff;
            height: 50px;
            padding-right: 50px;
        }
        i{
            position: absolute;
            cursor: pointer;
            right: 20px;
            top: 50%;
            transform: translateY(-50%);
        }
    }
    // recent post
    .recent-post-single{
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        &:last-child{
            margin-bottom: 0;
        }
        .thumbnail{
            margin-right: 20px;
            overflow: hidden;
            max-width: max-content;
            width: 100%;
            border-radius: 0;
            display: block;
            img{
                height: auto;
                transition: .3s;
                min-width: max-content;
            }
            &:hover{
                img{
                    transform: scale(1.2);
                }
            }
        }
        .user{
            display: flex;
            align-items: center;
            span{
                margin-left: 9px;
            }
        }
        .post-title{
            .title{
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 600;
                color: #1C2539;
                line-height: 26px;
                margin-top: 5px;
                transition: .3s;
                @media #{$small-mobile} {
                    font-size: 14px;
                    line-height: 26px;
                    margin-top: 0;
                }
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
    }
    .gallery-inner{
        display: flex;
        flex-direction: column;
        .single-row{
            display: flex;
            align-items: center;
            justify-content: space-between;
            a{
                overflow: hidden;
                border-radius: 0;
                @media #{$small-mobile} {
                    display: block;
                    width: 100%;
                }
                img{
                    max-width: 86px;
                    height: auto;
                    transition: .3s;
                    @media #{$laptop-device} {
                        max-width: 80px;
                    }
                    @media #{$smlg-device} {
                        min-width: auto;
                    }
                    @media #{$md-layout} {
                        min-width: 193px;
                    }
                    @media #{$sm-layout} {
                        min-width: 135px;
                    }
                    @media #{$large-mobile} {
                        min-width: 140px;
                    }
                    @media #{$small-mobile} {
                        min-width: 80px;
                    }
                }
                &:hover{
                    img{
                        transform: scale(1.2);
                    }
                }
            }
            &.row-1{
                margin-bottom: 20px;
            }
        }
    }
    .tags-wrapper{
        display: flex;
        flex-wrap: wrap;
        margin-bottom: -10px;
        a{
            padding: 5px 13px;
            background: #fff;
            border-radius: 0;
            margin-right: 10px;
            margin-bottom: 10px;
            color: #1C2539;
            font-size: 14px;
            font-weight: 500;
            transition: .3s;
            &:hover{
                background: var(--color-primary);
                color: #fff;
                transform: translateY(-3px) scale(1.09);
            }
        }
    }
}



// service details
.service-left-sidebar-wized{
    padding: 40px;
    background: #F6F6F6;
    @media #{$large-mobile} {
        padding: 20px;
    }
    .topa-rea{
        margin-bottom: 40px;
        span.pre{
            color: #30373E;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: 44px;
        }
        .title{
            padding-bottom: 20px;
            border-bottom: 1px solid #E3E3E3;
            color: #30373E;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 44px;
        }
    }
    .body{
        .single-short-service{
            padding: 6px 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            background: #FFF;
            margin-bottom: 10px;
            transition: .3s;
            &:last-child{
                margin-bottom: 0;
            }
            .number{
                color: #30373E;
                margin-bottom: 0;
                color: #30373E;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 44px;  
                transition: .3s;
                flex-basis: 6%;
            }
            p{
                margin-bottom: 0;
                color: #30373E;
                font-size: 18px;
                font-style: normal;
                font-weight: 600;
                line-height: 44px;
                transition: .3s;
                flex-basis: 70%;
                @media #{$smlg-device} {
                    font-size: 16px;
                }
                @media #{$large-mobile} {
                    font-size: 14px;
                }
            }
            i{
                color: #30373E;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 16px;
                transition: .3s;
            }
            &:hover{
                background: var(--color-primary);
                transform: translateY(-2px) scale(1.01);
                .number{
                    color: #fff;
                }
                p{
                    color: #fff;
                }
                i{
                    color: #fff;
                }
            }
        }
        form{
            input{
                height: 50px;
                background: #FFFFFF;
                margin-bottom: 15px;
                padding: 15px;
                font-size: 14px;
                border: 1px solid transparent;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
            textarea{
                height: 130px;
                background: #fff;
                padding: 15px 15px;
                font-size: 14px;
                border: 1px solid transparent;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
            button{
                display: block;
                min-width: 100%;
                border-radius: 0;
                padding: 15px;
                margin-top: 20px;
            }
        }
    }
}

.blog-single-post-listing{
    margin-right: 30px;
    margin-bottom: 50px;
    @media #{$smlg-device} {
        margin-right: 0;
    }
    // details style hear...
    &.details{
        border-radius: 0;
        background: #F6F6F6;
        .thumbnail{
            border-radius: 0 0 0 0;
            &.details{
                border-radius: 0;
                width: 100%;
                max-width: max-content;
                @media #{$smlg-device} {
                    max-width: 100%;
                }
                @media #{$md-layout} {
                    margin-bottom: 20px;
                }
                @media #{$sm-layout} {
                    margin-bottom: 20px;
                }
                @media #{$large-mobile} {
                    margin-bottom: 0;
                }
            }
        }
        .rts-quote-area{
            padding: 30px 11px;
            background: #fff;
            border-radius: 0;
            margin-bottom: 40px;
            text-align: center;
            @media #{$md-layout} {
                padding: 30px;
            }
            @media #{$sm-layout} {
                padding: 10px;
                margin-bottom: 25px;
            }
            @media #{$small-mobile} {
                margin-top: 15px;
            }
            .title{
                color: #30373E;
                text-align: center;
                font-size: 18px;
                font-style: italic;
                font-weight: 500;
                line-height: 28px;
                letter-spacing: -0.36px;
                text-transform: capitalize;
                margin-bottom: 0;
                @media #{$small-mobile} {
                    font-size: 16px;
                    margin-bottom: 15px;
                }
            }
            .name{
                font-size: 18px;
                color: var(--color-primary);
                font-weight: 700;
            }
            span{
                display: block;
                font-weight: 400;
                font-size: 14px;
                color: #5D666F;
            }
        }
        .check-area-details{
            .single-check{
                display: flex;
                align-items: center;
                margin-bottom: 5px;
                i{
                    margin-right: 15px;
                    color: var(--color-primary);
                    @media #{$small-mobile} {
                    margin-top: -26px;
                    }
                }
                span{
                    color: #5D666F;
                }
            }
        }
        .details-tag{
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            @media #{$small-mobile} {
                justify-content: flex-start;
                gap: 10px;
            }
            h6{
                margin-bottom: 0;
                font-size: 18px;
                margin-right: 15px;
            }
            button{
                padding: 8px 12px;
                background: #fff;
                max-width: max-content;
                margin-left: 10px;
                font-size: 14px;
                font-weight: 500;
                border-radius: 5px;
                color: #1C2539;
                transition: .3s;
                &:last-child{
                    @media #{$laptop-device} {
                        margin-top: 10px;
                        margin-left: -2px;
                    }
                }
                &:hover{
                    background: var(--color-primary);
                    color: #fff;
                    transform: translateY(-2px) scale(1.02);
                }
            }
        }
        .details-share{
            display: flex;
            align-items: center;
            justify-content: flex-end;
            @media #{$md-layout} {
                justify-content: flex-start;
                margin-top: 30px;
            }
            @media #{$sm-layout} {
                justify-content: flex-start;
                margin-top: 30px;
            }
            @media #{$large-mobile} {
                justify-content: flex-start;
                margin-top: 20px;
            }
            button{
                max-width: max-content;
                position: relative;
                z-index: 1;
                margin-left: 23px;
                color: #1C2539;
                transition: .3s;
                font-size: 14px;
                &::after{
                    position: absolute;
                    content: '';
                    background: #F6F6F6;
                    height: 40px;
                    width: 40px;
                    border-radius: 50%;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    z-index: -1;
                    transition: .3s;
                }
                &:hover{
                    color: #fff;
                    transform: scale(1.1);
                    &::after{
                        background: var(--color-primary);
                    }
                }
            }
            h6{
                font-size: 18px;
                margin-bottom: 0;
                margin-right: 15px;
            }
        }
        .author-area{
            margin-top: 44px;
            display: flex;
            align-items: center;
            padding: 40px 0;
            border-top: 1px solid #E6E9F0;
            border-bottom: 1px solid #E6E9F0;
            @media #{$sm-layout} {
                align-items: flex-start;
            }
            @media #{$large-mobile} {
                flex-wrap: wrap;
            }
            .thumbnail{
                margin-right: 30px;
                @media #{$sm-layout} {
                    margin-right: 0;
                }
            }
            .author-details{
                @media #{$sm-layout} {
                    margin-left: 15px;
                }
                h5{
                    margin-bottom: 10px;
                }
                p{
                    line-height: 26px;
                }
            }
        }

    }
    &.inner{
        border: none;
        max-width: 950px;
        margin: 0 auto;
    }
    &.inner2{
        .blog-listing-content{
            .rts-quote-area{
                position: relative;
                background: #000;
                padding-top: 120px;
                &::before{
                    position: absolute;
                    left: 50%;
                    top: 40px;
                    transform: translate(-50%,0);
                    content: "\f10d";
                    font-family: "Font Awesome 5 Pro";
                    width: 60px;
                    height: 60px;
                    line-height: 60px;
                    border-radius: 50%;
                    background: #fff;
                    color: var(--color-primary);
                    font-weight: 900;
                    font-size: 20px;
                }
                .title{
                    color: #f7f7f7;
                }
                
            }
        }
    }

    .replay-area-details{
        margin-top: 40px;
        form{
            input{
                height: 55px;
                border-radius: 0;
                background: #ffffff;
                border: 1px solid transparent;
                border: 1px solid #EAEAEA;
                box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.01);
                border-radius: 0;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
            textarea{
                border-radius: 0;
                background: #fff;
                height: 140px;
                margin-top: 20px;
                padding: 15px;
                border: 1px solid transparent;
                border: 1px solid #EAEAEA;
                box-shadow: 0px 4px 47px rgba(0, 0, 0, 0.01);
                border-radius: 0;
                &:focus{
                    border: 1px solid var(--color-primary);
                }
            }
        }
    }


    .thumbnail{
        overflow: hidden;
        img{
            transition: .3s;
            width: 100%;
        }
        &:hover{
            img{
                transform: scale(1.1);
            }
        }
    }
    .blog-listing-content{
        padding: 50px;
        @media #{$sm-layout} {
            padding: 25px 10px;
        }
        .user-info{
            display: flex;
            align-items: center;
            margin-bottom: 15px;
            flex-wrap: wrap;
            .single{
                margin-right: 30px;
                min-width: max-content;
                @media #{$sm-layout} {
                    margin-right: 5px;
                }
                @media #{$large-mobile} {
                    margin-right: 5px;
                }
                i{
                    margin-right: 10px;
                    color: var(--color-primary);
                    @media #{$large-mobile} {
                        margin-right: 2px;
                        font-size: 14px;
                    }
                }
                span{
                    @media #{$large-mobile} {
                        font-size: 13px;
                    }
                }
            }
        }
        .blog-title{
            transition: .3s;
            .title{
                transition: .3s;
                margin-bottom: 16px;
            }
            &:hover{
                .title{
                    color: var(--color-primary);
                }
            }
        }
        p{
            &.disc{
                font-size: 16px;
                line-height: 26px;
                // margin-bottom: 32px;
                @media #{$small-mobile} {
                    margin-bottom: 15px;
                }
            }
        }
        a{
            &.rts-btn{
                margin-top: 35px;
                display: block;
                max-width: max-content;
                border-radius: 0;
                padding: 18px 35px;
                @media #{$small-mobile} {
                    margin-top: 20px;
                }
            }
        }
    }
}