/*---------------------------
 CSS Index  
****************************

1. variables
2. typography
3. spacing
4. reset
5. forms
6. mixins
7. shortcode
8. common
9. animations
10. text-animation
11. sal animation
12. header
13. side-bar
14. mobile-menu
15. search-show
16. breadcrumb
17. blog
18. blog-list
19. banner
20. contact
21. about
22. calender
23. service
24. gallery
25. shop
26. team
27. course
28. cta
29. event
30. wedding
31. booking
32. footer
33. faq




/* Default  */

@import'default/variables';
@import'default/typography';
@import'default/spacing';
@import'default/reset';
@import'default/forms';
@import'default/mixins';
@import'default/shortcode';
@import'default/common';
@import'default/animations';
@import'default/text-animation';
@import'default/sal';



/* Header area style  */
@import'elements/header';
@import'elements/side-bar';
@import'elements/mobile-menu';
@import'elements/search-show';
@import'elements/breadcrumb';

/* Hero area style */
@import'elements/blog/blog';

/* Hero area style */
@import'elements/blog-list';

/* Benar area style */
@import'elements/banner';

/* Contact Form */
@import'elements/contact';

/* About Form */
@import'elements/about';

/* About Form */
@import'elements/calender';

/* Service area style */
@import'elements/service';

/* Gallery area style */
@import'elements/gallery';

/* Shop area style */
@import'elements/shop';

/* Team area style */
@import'elements/team';

/* course area style */
@import'elements/course';

/* cta area style */
@import'elements/cta';

/* cta area style */
@import'elements/event';

/* wedding area style */
@import'elements/wedding';


/* booking area style */
@import'elements/booking';

/* Footer area style */
@import'elements/footer';

/* Footer area style */
@import'elements/faq';





