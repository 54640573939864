// **************************************** =========== Start Service Scss  ===========  ****************************************//

.rts-gallery-section{
    .rts-section-title{
        max-width: 35%;
        margin: auto;
        @media(max-width:1200px){
            max-width: 55%;
        }
        @media #{$sm-layout} {
            max-width: 100%;
        }
        .heading-title{
            font-weight: 400;
        }
    }
    .section-inner{
        .gallery-wrapper{
            position: relative;
            z-index: 1;
            img{
                @media(max-width:768px){
                    width: 100%;
                }
            }
            &:hover{
                .gallery-image{
                    img{
                        transform: scale(110%);
                    }
                    .item-overlay{
                       opacity: 1;
                       visibility: visible;
                    }
                }
                .center-icon{
                    opacity: 1;
                }
            }
            .gallery-image{
                overflow: hidden;
                display: block;
                z-index: 1;
                cursor: pointer;
                -webkit-transition: 0.3s;
                -o-transition: 0.3s;
                transition: 0.3s;
                .item-overlay{
                    position: absolute;
                    top: 0px;
                    background: rgba(17, 17, 17, 0.50);
                    width: 100%;
                    height: 100%;
                    opacity: 0;
                    left: 0;
                    visibility: hidden;
                    overflow: hidden;
                    -webkit-transition: 0.3s ease-in;
                    -o-transition: 0.3s ease-in;
                    transition: 0.3s ease-in;
                    span{
                        color: #ffff;
                        font-size: 50px;
                        position: absolute;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                    }
                }
            }
            .center-icon{
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                transition: all .3s;
                opacity: 0;
                z-index: 1;
            }
        }
        .swiper-pagination2{
            position: relative;
            width: 100px;
            margin: 60px auto 0 auto;
            .swiper-pagination-bullet{
                border: 1px solid var(--color-primary);
                opacity: 1;
                background: none;
                width: 12px;
                height: 12px;
                position: relative;
                transition: all .3s;
                &:hover{
                    &::after{
                        display: block;
                    }
                }
                &::after{
                    content: '';
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    border-radius: 50%;
                    transform: translate(-50%, -50%);
                    left: 50%;
                    top: 50%;
                    background: var(--color-primary);
                    display: none;
                    transition: all .3s;
                }
                &.swiper-pagination-bullet-active{
                    &::after{
                        display: block;
                    }
                }
            }
        }
    }
    &.home-2{
        @media(max-width:1366px){
            padding-left: 20px;
            padding-right: 20px;
        }
        .section-inner{
            display: flex;
            gap: 30px;
            justify-content: center;
            @media(max-width:1200px){
                gap: 20px;
            }
            @media(max-width:991px){
                flex-wrap: wrap;
            }
            .section-left{
                @media(max-width:991px){
                    width: 100%;
                }
                @media(max-width:768px){
                    width: 100%;
                }
                .image-area{
                    @media(max-width:991px){
                        width: 100%;
                    }
                    .gallery-wrapper{
                        margin-bottom: 30px;
                        @media(max-width:1200px){
                            margin-bottom: 20px;
                        }
                        @media(max-width:991px){
                            width: 100%;
                            img{
                                width: 100%;
                            }
                        }
                    }
                }
            }
            .section-mid{
                @media(max-width:991px){
                    width: 100%;
                    margin-bottom: 10px;
                    img{
                        width: 100%;
                    }
                }
            }
            .section-right{
                @media(max-width: 768px){
                    width: 100%;
                }
                .section-right-top,
                .section-right-bottom{
                    @media(max-width: 768px){
                        width: 100%;
                        .gallery-wrapper{
                            width: 100%;
                            margin-bottom: 20px;
                        }
                    }
                    .image-area{
                        display: flex;
                        gap: 30px;
                        @media(max-width:1200px){
                            gap: 20px;
                        }
                        @media(max-width: 768px){
                            display: block;
                            img{
                                width: 100%;
                            }
                        }
                    }
                }
                .section-right-top{
                    margin-bottom: 30px;
                    @media(max-width:1200px){
                        margin-bottom: 20px;
                    }
                    @media(max-width: 768px){
                        img{
                            width: 100%;
                        }
                    }
                }
            }
            .gallery-wrapper{
                .gallery-image{
                    .item-overlay{
                        .inner{
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            position: absolute;
                            width: 80%;
                            bottom: 20px;
                            left: 50%;
                            transform: translateX(-50%);
                            .content{
                                .sub-title{
                                    color: var(--color-white);
                                    margin-bottom: 5px;
                                }
                                .title{
                                    color: var(--color-white);
                                    font-size: 32px;
                                    font-weight: 700;
                                    line-height: 34px;
                                    text-transform: capitalize;
                                }
                            }
                            span{
                                position: unset;
                                transform: none;
                            }
                        }
                    }
                }
            }
        }
    }
}


.rta-gallery-area-main{
    .section-inner{
        height: 100%;
        .gallery-wrapper{
            height: 100%;
            img{
                height: 100%;
            }
        }
    }
}

.gallery-wrapper.main{
    img{
        width: 100%;
    }
    position: relative;
    .item-overlay{
        position: absolute;
        top: 0px;
        background: rgba(17, 17, 17, 0.5);
        width: 100%;
        height: 100%;
        opacity: 0;
        left: 0;
        visibility: hidden;
        overflow: hidden;
        -webkit-transition: 0.3s ease-in;
        -o-transition: 0.3s ease-in;
        transition: 0.3s ease-in;
        .inner{
            display: flex;
            align-items: center;
            justify-content: space-between;
            position: absolute;
            width: 80%;
            bottom: 20px;
            left: 50%;
            transform: translateX(-50%);
            span{
                position: unset;
                transform: none;
                color: #ffff;
                font-size: 50px;
                position: absolute;
                top: 50%;
                left: 90% !important;
                transform: translate(-50%, -50%);
            }
            .content{
                .sub-title{
                    color: var(--color-white);
                    margin-bottom: 5px;
                }
                .title{
                    color: var(--color-white);
                    font-size: 32px;
                    font-weight: 700;
                    line-height: 34px;
                    text-transform: capitalize;
                }
            }
        }
    }
    &:hover{
        .item-overlay{
            opacity: 1;
            visibility: visible;
            .inner{
                display: flex;
                align-items: center;
                justify-content: space-between;
                position: absolute;
                width: 80%;
                bottom: 20px;
                left: 50%;
                transform: translateX(-50%);
            }
        }
    }
}